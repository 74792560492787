import { formatDate } from "../../utils/utils";

export type DateValue = Date | DatePresets;
export type DatePresets =
    | "Now"
    | "15 mins ago"
    | "30 mins ago"
    | "1 hour ago"
    | "1 day ago"
    | "1 week ago"
    | "1 month ago"
    | "3 months ago"
    | "1 year ago";

export function formatDateValue(dv: DateValue): string {
    if (dv instanceof Date) {
        return formatDate(dv);
    } else {
        return dv;
    }
}

const MINUTE_IN_MS = 60000;
const HOUR_IN_MS = 60 * MINUTE_IN_MS;

export function convertToDate(dv: DateValue): Date {
    if (dv instanceof Date) {
        return dv;
    }
    let deltaMs = 0;
    switch (dv) {
        case "Now":
            break;
        case "15 mins ago":
            deltaMs = 15 * MINUTE_IN_MS;
            break;
        case "30 mins ago":
            deltaMs = 30 * MINUTE_IN_MS;
            break;
        case "1 hour ago":
            deltaMs = HOUR_IN_MS;
            break;
        case "1 day ago":
            deltaMs = 24 * HOUR_IN_MS;
            break;
        case "1 week ago":
            deltaMs = 7 * 24 * HOUR_IN_MS;
            break;
        case "1 month ago":
            deltaMs = 7 * 24 * 30 * HOUR_IN_MS;
            break;
        case "3 months ago":
            deltaMs = 7 * 24 * 30 * 3 * HOUR_IN_MS;
            break;
        case "1 year ago":
            deltaMs = 7 * 24 * 30 * 12 * HOUR_IN_MS;
            break;
    }
    const d = new Date();
    d.setTime(d.getTime() - deltaMs);
    return d;
}

export function toISOString(dv: DateValue): string {
    const d = convertToDate(dv);
    return d.toISOString();
}

export function toUtcString(dv: DateValue): string {
    const d = convertToDate(dv);
    return d.toUTCString();
}
