import { ChangeEvent } from "react";
import classnames from "classnames";

import SearchIcon from "icons/search.svg";

import styles from "./styles/TableSearchColumnHeader.module.scss";

const TableSearchColumnHeader = ({
    className,
    placeholder = "Search...",
    ...props
}: {
    className?: string;
    placeholder?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
}) => (
    <div className={classnames(styles.root, className)}>
        <SearchIcon />
        <input
            placeholder={placeholder}
            {...props}
            className="bg-transparent"
        />
    </div>
);

export default TableSearchColumnHeader;
