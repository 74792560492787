import CodeIcon from "icons/code.svg";
import styles from "../styles/EntityPage.module.scss";
import Autogenerated from "./AutogeneratedPill";
import { isAutogenExtractor } from "../../../utils/utils";
import ViewDefinition from "../../ViewDefinition";
import type { Extractor } from "../../../utils/types";
import Metadata from "./Metadata";

const Extractor = ({ extractor }: { extractor: Extractor }) => {
    return (
        <Metadata
            icon={<CodeIcon />}
            keyLabel="Extractor"
            keyValue={
                <span>
                    {extractor && extractor.code ? (
                        isAutogenExtractor(extractor) ? (
                            <Autogenerated />
                        ) : (
                            <div>
                                <ViewDefinition
                                    code={extractor.code}
                                    name={extractor.name}
                                    label={extractor.name}
                                />
                            </div>
                        )
                    ) : (
                        "No Extractor"
                    )}
                </span>
            }
        />
    );
};

export default Extractor;
