import { stringCompare } from "../utils/utils";
import FeatureIcon from "icons/feature.svg";
import { Column } from "./Table";
import { DOC_LINKS } from "../constants/docs";
import { Feature } from "../utils/types";
import { useUpdateEntityParams } from "../../context/CurrentView";
import { EntityDescriptor, EntityOwner, EntityTable } from "./EntityTable";
import { ConsoleDataTable } from "shared/componentsv2/ConsoleDataTable";
import { DataTableColumnHeader } from "shared/componentsv2/DataTableColumnHeader";
import { ColumnDef } from "@tanstack/react-table";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    features: Feature[];
    isLearnMore: boolean;
    windowed?: boolean;
    emptyText?: string;
}

function FeaturesTable({
    features,
    className,
    emptyText,
    isLearnMore,
    windowed,
    ...rest
}: Props): JSX.Element {
    const { updateEntityParams: navigate } = useUpdateEntityParams();

    const columns: ColumnDef<Feature>[] = [
        {
            id: "feature",
            accessorKey: "name",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title="Feature" />
                );
            },
            cell: ({ row: { original: fs } }) => {
                return (
                    <EntityDescriptor
                        name={fs.name}
                        entityIcon={<FeatureIcon />}
                        metaDescription={fs.description}
                    />
                );
            },
            size: 500,
        },
        {
            id: "featureset",
            accessorKey: "featureset",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title="Featureset" />
                );
            },
            cell: ({ row: { original: fs } }) => {
                return <EntityDescriptor name={fs.featureset || ""} />;
            },
        },
        {
            accessorKey: "owner",

            header: ({ column }) => {
                return <DataTableColumnHeader column={column} title="Owner" />;
            },
            cell: ({ row: { original: fs } }) => {
                return <EntityOwner owner={fs.owner || ""} />;
            },
        },
    ];

    return (
        <ConsoleDataTable
            data={features}
            columns={columns}
            onRowClick={(row) => navigate("feature", row.name, row.featureset)}
            dataUnit="feature"
            showFooter
            fixedHeader
            emptyState={{
                dataExists: !isLearnMore,
                emptyText: emptyText,
                learnMore: DOC_LINKS.featureset,
            }}
            {...rest}
        />
    );
}

export default FeaturesTable;
