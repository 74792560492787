import { ColumnDef } from "@tanstack/react-table";
import { EntityDescriptor, EntityOwner } from "shared/components/EntityTable";
import Tooltip from "shared/components/Tooltip";
import { DataTableColumnHeader } from "shared/componentsv2/DataTableColumnHeader";
import { Dataset, SourceInfo } from "shared/utils/types";
import styles from "../styles/DatasetsPage.module.scss";
import DatasetIcon from "icons/dataset.svg";
import { getSourceTypeIcon } from "shared/utils/utils";

const getSourcedDerivedFromContent = (sources: string[], unit: string) => {
    if (sources.length > 1) {
        return `${sources.length} ${unit}`;
    }
    return sources[0];
};

export const getColumns = (sourceInfo?: SourceInfo): ColumnDef<Dataset>[] => [
    {
        accessorKey: "name",
        id: "dataset",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Dataset" />;
        },
        cell: ({ row: { original: ds } }) => {
            const datasetSourceKeys = Object.keys(sourceInfo || {}).filter(
                (key) =>
                    sourceInfo?.[key].datasets.find(
                        (dataset) => dataset.name === ds.name
                    )
            );
            const datasetSources = datasetSourceKeys?.map(
                (dkey) => sourceInfo?.[dkey].metadata
            );
            const parentDatasets = ds.pipelines.flatMap(
                (p) => p.input_dataset_names
            );
            const sourceNames = datasetSources.map((s) => `${s?.source_type}`);
            return (
                <EntityDescriptor
                    name={ds.name}
                    metaDescription={
                        <>
                            {ds.is_source_dataset ? (
                                <Tooltip
                                    content={`Sourced from ${sourceNames.join(
                                        ", "
                                    )}`}
                                >
                                    <span className={styles.sourcedFrom}>
                                        Sourced From
                                        {datasetSources.map((source, index) => {
                                            const Icon = getSourceTypeIcon(
                                                source?.source_type || ""
                                            );
                                            return <Icon key={index} />;
                                        })}
                                        {getSourcedDerivedFromContent(
                                            sourceNames,
                                            "Sources"
                                        )}
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    content={`Derived from ${parentDatasets.join(
                                        ", "
                                    )}`}
                                >
                                    <span className={styles.sourcedFrom}>
                                        Derived From
                                        <DatasetIcon />
                                        {getSourcedDerivedFromContent(
                                            parentDatasets,
                                            "Datasets"
                                        )}
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    }
                    tags={ds.tags}
                    entityIcon={<DatasetIcon />}
                />
            );
        },
        size: 500,
    },
    {
        accessorKey: "owner",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Owner" />;
        },
        cell: ({ row: { original: ds } }) => {
            return <EntityOwner owner={ds.owner} />;
        },
    },
];
