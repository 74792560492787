import useSWR from "swr";
import styles from "../styles/Ingestion.module.scss";
import { axiosFetcherWithParams } from "../../../utils/utils";
import { Dataset } from "../../../utils/types";
import ClockIcon from "icons/timer.svg";
import Spinner from "shared/components/Spinner";
import { formatDateTime } from "shared/components/graph/Graph";

export function LastUpdatedTimestamp({
    dataset,
    branchName,
}: {
    branchName: string;
    dataset: Dataset;
}) {
    const { data, isLoading, error } = useSWR(
        [
            "post",
            `/branch/${branchName}/metrics/latest_timestamp`,
            {
                dataset_name: dataset.name,
            },
        ],
        axiosFetcherWithParams
    );
    const lastUpdatedDate = data?.data?.result?.[0]?.value?.[1];

    return (
        <div className={styles.ingesstionLifetimeRow}>
            <div className={styles.ingestionLifetimeKey}>
                <ClockIcon />
                <div>Latest Timestamp</div>
            </div>
            <div className={styles.ingestionLifetimeAbs}>
                {isLoading ? (
                    <Spinner className={styles.spinner} />
                ) : lastUpdatedDate && !error ? (
                    formatDateTime(new Date(lastUpdatedDate * 1000))
                ) : (
                    "-"
                )}
            </div>
        </div>
    );
}
