import axios from "axios";
import { User } from "../models";
import SourceIcon from "icons/source.svg";
import KafkaIcon from "icons/kafka.svg";
import MySQLIcon from "icons/mysql.svg";
import PostgresIcon from "icons/postgres.svg";
import SnowflakeIcon from "icons/snowflake.svg";
import BigQueryIcon from "icons/bigquery.svg";
import WebhookIcon from "icons/webhook.svg";
import AWSIcon from "icons/aws.svg";
import { Extractor } from "./types";
import { stringify } from "lossless-json";

export const axiosFetcher = ([method, path]: ["get" | "post", string]) => {
    return axios[method](path).then(({ data }) => data);
};

export const axiosFetcherWithParams = ([method, path, params]: [
    "get" | "post",
    string,
    object
]) => {
    return axios[method](path, params).then(({ data }) => data);
};

export function tabFromHash(): string {
    if (window.location.hash) {
        return window.location.hash.substring(1);
    }
    return "";
}

export function fullname(u: User): string {
    return `${u.first_name} ${u.last_name}`;
}

export function maybePlural(
    n: number,
    singular: string,
    plural?: string
): string {
    if (n && n < 2) {
        return singular;
    }
    return (plural || singular + "s").toLowerCase();
}

export function percentile(p: number): string {
    return `${Math.round(p * 100)}%`;
}

export function formatDate(date: Date): string {
    return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
}

export function valueCompare<T>(x: T, y: T): number {
    return x < y ? -1 : x == y ? 0 : 1;
}

export function stringCompare(x: string, y: string): number {
    return x < y ? -1 : x == y ? 0 : 1;
}

export function dateCompare(x: Date, y: Date): number {
    return x.getTime() < y.getTime() ? -1 : x.getTime() == y.getTime() ? 0 : 1;
}

export function durationInDays(d: { secs: number }): number {
    return Math.round(d.secs / 60 / 60 / 24);
}

export function tieredHome(tierName: string): string {
    return `/tier/${tierName}`;
}

export function tieredLink(tierName: string | undefined, link: string): string {
    if (tierName) {
        return `/tier/${tierName}/${link}`;
    }
    return `/${link}`;
}

export function tieredView(tierName: string | undefined): string {
    if (tierName) {
        return `/tier/${tierName}/view`;
    }
    return `/view`;
}

export function tieredMetricsLink(
    tierName: string | undefined,
    link: string | undefined
): string {
    if (tierName) {
        return `/tier/${tierName}/metrics/${link}`;
    }
    return `/metrics/${link}`;
}

export function tieredDatasetLink(
    tierName: string | undefined,
    datasetName: string | undefined
): string {
    if (tierName) {
        return `/tier/${tierName}/dataset/${datasetName}`;
    }
    return `/dataset/${datasetName}`;
}

export function tieredFeaturesetLink(
    tierName: string | undefined,
    featuresetName: string | undefined
): string {
    if (tierName) {
        return `/tier/${tierName}/featureset/${featuresetName}`;
    }
    return `/featureset/${featuresetName}`;
}

export function tieredFeatureLink(
    tierName: string | undefined,
    featuresetName: string | undefined,
    featureName: string | undefined
): string {
    if (tierName) {
        return `/tier/${tierName}/featureset/${featuresetName}/feature/${featureName}`;
    }
    return `/featureset/${featuresetName}/feature/${featureName}`;
}

export function tieredExtractorLink(
    tierName: string | undefined,
    featuresetName: string | undefined,
    extractorName: string | undefined
): string {
    if (tierName) {
        return `/tier/${tierName}/featureset/${featuresetName}/extractor/${extractorName}`;
    }
    return `/featureset/${featuresetName}/extractor/${extractorName}`;
}

export function yTickLabelAbbreviator(current: string): string {
    return Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 1,
    }).format(Number(current));
}

export function getSourceTypeIcon(type: string) {
    switch (type) {
        case "S3":
            return AWSIcon;
        case "Bigquery":
            return BigQueryIcon;
        case "Snowflake":
            return SnowflakeIcon;
        case "MySql":
            return MySQLIcon;
        case "Postgres":
            return PostgresIcon;
        case "Webhook":
            return WebhookIcon;
        case "Kafka":
            return KafkaIcon;
        default:
            return SourceIcon;
    }
}

export const displayFormattedValue = (value: any) => {
    if (
        typeof value === "object" ||
        typeof value === "function" ||
        typeof value === "boolean"
    ) {
        return stringify(value);
    }

    if (typeof value === "bigint") {
        return value.toString();
    }
    return value;
};

function encodeBranchName(branchName: string): string {
    return encodeURIComponent(branchName);
}

export function branchedHome(branchName: string): string {
    return `/branch/${encodeBranchName(branchName)}`;
}

export function branchedHomeLink(
    branchName: string | undefined,
    link: string
): string {
    if (branchName) {
        return `/branch/${encodeBranchName(branchName)}/${link}`;
    }
    return `/${link}`;
}

export function branchedLink(
    branchName: string | undefined,
    link: string
): string {
    if (branchName) {
        return `/branch/${encodeBranchName(branchName)}/${link}`;
    }
    return `/${link}`;
}

export function branchedView(branchName: string | undefined): string {
    if (branchName) {
        return `/branch/${encodeBranchName(branchName)}/view`;
    }
    return `/view`;
}

export function branchedMetricsLink(
    branchName: string | undefined,
    link: string | undefined
): string {
    if (branchName) {
        return `/branch/${encodeBranchName(branchName)}/metrics/${link}`;
    }
    return `/metrics/${link}`;
}

export function branchedDatasetLink(
    branchName: string | undefined,
    datasetName: string | undefined
): string {
    if (branchName) {
        return `/branch/${encodeBranchName(branchName)}/dataset/${datasetName}`;
    }
    return `/dataset/${datasetName}`;
}

export function branchedFeaturesetLink(
    branchName: string | undefined,
    featuresetName: string | undefined
): string {
    if (branchName) {
        return `/branch/${encodeBranchName(
            branchName
        )}/featureset/${featuresetName}`;
    }
    return `/featureset/${featuresetName}`;
}

export function branchedFeatureLink(
    branchName: string | undefined,
    featuresetName: string | undefined,
    featureName: string | undefined
): string {
    if (branchName) {
        return `/branch/${encodeBranchName(
            branchName
        )}/featureset/${featuresetName}/feature/${featureName}`;
    }
    return `/featureset/${featuresetName}/feature/${featureName}`;
}

export function branchedExtractorLink(
    branchName: string | undefined,
    featuresetName: string | undefined,
    extractorName: string | undefined
): string {
    if (branchName) {
        return `/branch/${encodeBranchName(
            branchName
        )}/featureset/${featuresetName}/extractor/${extractorName}`;
    }
    return `/featureset/${featuresetName}/extractor/${extractorName}`;
}

export const isAutogenExtractor = (extractor: Extractor) => {
    const firstLine = extractor.code.split("\n")[0];
    const firstLineWords = firstLine.split(" ");
    if (firstLineWords[1] === "autogenerated") {
        return true;
    }
    return false;
};

export function nFormatter(num: number, digits: number) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "Qa" },
        { value: 1e18, symbol: "Qi" },
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find((item) => num >= item.value);
    return item
        ? (num / item.value)
              .toFixed(digits)
              .replace(regexp, "")
              .concat(item.symbol)
        : "0";
}

export function getElapsedTime(
    startTime: number,
    hms = false,
    currentTime: number = Date.now()
) {
    let delta = Math.abs(currentTime - startTime) / 1000;
    const result: { [key: string]: number } = {};
    const structure: { [key: string]: number } = hms
        ? {
              hour: 3600,
              minute: 60,
              second: 1,
          }
        : {
              year: 31536000,
              month: 2592000,
              week: 604800,
              day: 86400,
              hour: 3600,
              minute: 60,
              second: 1,
          };

    Object.keys(structure).forEach(function (key) {
        result[key] = Math.floor(delta / structure[key]);
        delta -= result[key] * structure[key];
    });

    return result;
}

const padZeroIfRequired = (num: string) => {
    return num.length === 1 ? "0" + num : num;
};

export const elapsedTimeFormatterHMS = (result: { [key: string]: number }) => {
    const order = ["hour", "minute", "second"];

    return order.reduce((acc, key) => {
        if (result[key]) {
            acc += padZeroIfRequired(`${result[key]}`);
        } else {
            acc += "00";
        }

        if (key !== "second") {
            acc += ":";
        }

        return acc;
    }, "");
};

export const elapsedTimeFormatterString = (result: {
    [key: string]: number;
}) => {
    const order = ["year", "month", "week", "day", "hour", "minute"];

    return order
        .reduce((acc, key) => {
            if (result[key]) {
                acc += `${result[key]} ${key} `;
            }
            return acc;
        }, "")
        .trimEnd();
};

export const downloadCSV = (csvString: string, filename = "table_data.csv"): void => {
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    // Append link to the body to make it clickable in Firefox
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};



export const convertToCSV = (data: { [key: string]: any }[]): string => {
    if (!Array.isArray(data) || data.length === 0) {
        return "";
    }

    const headers = Object.keys(data[0]);

    const csvRows = data.map(row => {
        return headers.map(header => {
            let cell = row[header];

            // Convert BigInt to string
            if (typeof cell === 'bigint') {
                cell = cell.toString();
            }

            if (cell === null || cell === undefined) {
                cell = "";
            }

            cell = String(cell);

            // Escape double quotes by replacing " with ""
            cell = cell.replace(/"/g, '""');

            return `"${cell}"`;
        }).join(",");
    });

    const csvString = [headers.map(header => `"${header.replace(/"/g, '""')}"`).join(","), ...csvRows].join("\n");
    return csvString;
};
