import { useState } from "react";
import styles from "./styles/ThirdPartyAuth.module.scss";
import axios from "axios";
import Spinner from "./Spinner";

interface Props {
    icon: JSX.Element;
    text: string;
    href?: string;
    onClickUrl?: string;
}

function ThirdPartyAuth({ icon, text, href, onClickUrl }: Props): JSX.Element {
    const [isLoading, setIsLoading] = useState(true);
    const handleOnClick = async () => {
        setIsLoading(true);
        if (!onClickUrl) return;
        try {
            // Make the POST request to initiate the SAML login
            const response = await axios.post(
                onClickUrl,
                {},
                { withCredentials: true }
            );

            if (response.data.responseUrl) {
                window.location.href = response.data.responseUrl;
            } else {
                console.error("No response URL provided");
            }
        } catch (error) {
            console.error("Error during SAML login initiation:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            {href ? (
                <form action={href} method="post">
                    <button
                        type="submit"
                        color="primary"
                        className={styles.button}
                    >
                        {icon}
                        <span>{text}</span>
                    </button>
                </form>
            ) : onClickUrl ? (
                <button
                    type="button"
                    onClick={handleOnClick}
                    color="primary"
                    className={styles.button}
                >
                    {isLoading ? icon : <Spinner />}
                    <span>{text}</span>
                </button>
            ) : null}
        </div>
    );
}

export default ThirdPartyAuth;
