import { useState } from "react";
import { DataTable, DataTableProps } from "./DataTable";
import { Table } from "@tanstack/react-table";
import { DataTableViewOptions } from "./DataTableViewOptions";
import { createPortal } from "react-dom";
import { ScrollArea } from "./ScrollArea";
import EmptyState from "shared/components/EmptyState";

interface ConsoleDataTableProps<T> extends DataTableProps<T, unknown> {
    dataUnit?: string;
    fixedHeader?: boolean;
    showFooter?: boolean;
    showDisplayOptions?: boolean;
    windowed?: boolean;
    emptyState: {
        dataExists: boolean;
        emptyText?: string;
        learnMore?: string;
    };
}

export function ConsoleDataTable<T>(props: ConsoleDataTableProps<T>) {
    const consoleActionSelector = document.getElementById("console-actions");
    const { windowed = true } = props;
    const dataUnit = props.dataUnit || "row";

    return props.emptyState.dataExists ? (
        <>
            <ScrollArea
                className={
                    props.showFooter
                        ? `w-full overflow-x-auto h-[calc(100vh-176px)]`
                        : `h-[calc(100vh-120px)]`
                }
            >
                {" "}
                {/* height of header + footer = 176*/}
                <DataTable
                    columns={props.columns}
                    data={props.data}
                    onRowClick={props.onRowClick}
                    fixedHeader={props.fixedHeader}
                    columnVisibilityState={props.columnVisibilityState}
                    windowed={windowed}
                />
            </ScrollArea>
            {props.showFooter && (
                <div className="flex items-center space-x-2 py-4 px-4 sticky bottom-0 bg-card font-bold text-sm">
                    <div>
                        {props.data.length} total {dataUnit}(s)
                    </div>
                </div>
            )}
        </>
    ) : (
        <EmptyState
            text={props.emptyState?.emptyText}
            learnMore={props.emptyState?.learnMore}
        />
    );
}
