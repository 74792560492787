import styles from "./styles/IngestionPage.module.scss";
import DatasetIcon from "icons/dataset.svg";
import {
    stringCompare,
    branchedLink,
    axiosFetcherWithParams,
    elapsedTimeFormatterHMS,
    getElapsedTime,
    nFormatter,
} from "shared/utils/utils";
import { useParams, useSearchParams } from "react-router-dom";
import SearchBar from "shared/components/search/SearchBar";
import SourceIcon from "icons/source.svg";
import CheckCircleIcon from "icons/check-circle.svg";
import TimerIcon from "icons/timer.svg";
import RowsDroppedIcon from "icons/rows-dropped.svg";
import RowsFailedIcon from "icons/rows-failed.svg";
import RowsFilteredIcon from "icons/rows-filtered.svg";
import { useCallback, useContext } from "react";
import { Config, defaultVerb, Filter } from "shared/components/search/filters";
import Table, { Column } from "shared/components/Table";
import { CurrentViewContext } from "../../context/CurrentView";
import SourcesFilter from "shared/components/search/SourcesFilter";
import { INGESTION_NAV } from "shared/constants/navigation";
import {
    Dataset,
    DatasetIngestion,
    DatasetIngestionStatus,
    Source,
    SourceInfo,
} from "../../shared/utils/types";
import {
    IngestionDataset,
    IngestionIcon,
} from "shared/components/entityPage/metadataComponents/Ingestion";
import { parseISO } from "date-fns";
import useSWR from "swr";
import DatasetFilter from "shared/components/search/SourceFilter";
import Tooltip from "shared/components/Tooltip";
import EmptyState from "shared/components/EmptyState";
import ConsolePage from "../console-page/ConsolePage";
import { VERSION_SEPARATOR } from "shared/constants/constants";

const IngestionsPage = () => {
    const { branchName } = useParams();
    const [searchParams] = useSearchParams();
    const dataset = searchParams.get("dataset");
    const source = searchParams.get("source");
    const status = searchParams.get("status");

    const apiPath = `ingestion_stats`;

    const { data, isLoading } = useSWR(
        [
            "post",
            branchedLink(branchName, apiPath),
            { dataset, source, status },
        ],
        axiosFetcherWithParams,
        {
            refreshInterval: 120000,
        }
    );

    if (isLoading || !data || !data.stats) {
        return (
            <ConsolePage
                header={{
                    title: INGESTION_NAV.title,
                    icon: INGESTION_NAV.icon,
                }}
                content={
                    <div className={styles.loaderContainter}>
                        <EmptyState
                            loading={isLoading}
                            text="No ingestion jobs found"
                        />
                    </div>
                }
            />
        );
    }

    return <IngestionsTableLoaded ingestions={data.stats} />;
};

function IngestionsTableLoaded({
    ingestions: allDatasetIngestions,
}: {
    ingestions: DatasetIngestion["stats"];
}): JSX.Element {
    const { sourceInfo, viewInfo } = useContext(CurrentViewContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const searchConfig = buildSearchConfig(
        viewInfo?.datasets || [],
        sourceInfo as SourceInfo,
        !!searchParams.get("dataset")
    );

    const defaultFilters: Filter[] = [];
    searchParams.forEach((value, key) => {
        if (searchConfig.filterConfigs[key]) {
            defaultFilters.push({
                key,
                value: [value],
                verb: defaultVerb(searchConfig.filterConfigs[key], [value]),
            });
        }
    });

    const onDatasetClick = useCallback(
        (clickedDataset: string) => {
            const datasetParam = searchParams.get("dataset");
            if (datasetParam === clickedDataset) {
                return;
            }

            const newSearchParams = new URLSearchParams();
            newSearchParams.set("dataset", clickedDataset);
            setSearchParams(newSearchParams);
        },
        [searchParams, setSearchParams]
    );

    const getSourceObject = (source_name: string) => {
        return sourceInfo?.[source_name]?.metadata;
    };

    const columns: Column<DatasetIngestion["stats"][number]>[] = [
        {
            header: "Started At",
            renderFunc: (ds) => (
                <div>{parseISO(ds.started_at).toLocaleString()}</div>
            ),
            sortFunc: (x, y) => stringCompare(x.started_at, y.started_at),
        },
        {
            header: "Dataset",
            renderFunc: (ds) => (
                <IngestionDataset
                    source={getSourceObject(ds.source_name) as Source}
                    dataset={ds.dataset_name}
                    onClick={() =>
                        onDatasetClick(
                            ds.dataset_name.split(VERSION_SEPARATOR)[0]
                        )
                    }
                />
            ),
            sortFunc: (x, y) => stringCompare(x.dataset_name, y.dataset_name),
        },
        {
            header: "Status",
            renderFunc: (ds) => (
                <StatusPill
                    status={
                        ds.failed ? DatasetIngestionStatus.FAILED : ds.status
                    }
                    fraction={ds.rows_ingested / ds.rows_to_ingest}
                />
            ),
            sortFunc: (x, y) => stringCompare(x.status, y.status),
        },
        {
            header: "Rows",
            renderFunc: (ds) =>
                ds.status !== DatasetIngestionStatus.INITIALIZING &&
                !ds.failed && (
                    <div className={styles.rowsInfo}>
                        <div className={styles.rowCount}>
                            {nFormatter(ds.rows_ingested, 3)}
                            {ds.status === DatasetIngestionStatus.INGESTING ? (
                                <>
                                    &nbsp;/
                                    <div className={styles.rowCountSecondary}>
                                        &nbsp;
                                        {`${nFormatter(
                                            ds.rows_to_ingest,
                                            3
                                        )} total
                                        rows`}
                                    </div>
                                </>
                            ) : (
                                " total rows"
                            )}
                        </div>
                        <div className={styles.rowStatus}>
                            <Tooltip content="Rows Skipped">
                                <div className={styles.rowStatusChild}>
                                    <RowsDroppedIcon />
                                    {nFormatter(ds.rows_skipped, 2)}
                                </div>
                            </Tooltip>
                            <Tooltip content="Rows Failed">
                                <div className={styles.rowStatusChild}>
                                    <RowsFailedIcon />
                                    {nFormatter(ds.rows_failed, 2)}
                                </div>
                            </Tooltip>
                            <Tooltip content="Rows Filtered">
                                <div className={styles.rowStatusChild}>
                                    <RowsFilteredIcon />
                                    {nFormatter(ds.rows_filtered, 2)}
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                ),
        },
        {
            header: "Elapsed",
            renderFunc: (ds) => {
                return ds.status !== DatasetIngestionStatus.COMPLETE ? (
                    <div className={styles.timer}>
                        <TimerIcon />
                        {elapsedTimeFormatterHMS(
                            getElapsedTime(
                                parseISO(ds.started_at).getTime(),
                                true
                            )
                        )}
                    </div>
                ) : (
                    <div className={styles.timer}>
                        <TimerIcon />
                        {elapsedTimeFormatterHMS(
                            getElapsedTime(
                                parseISO(ds.started_at).getTime(),
                                true,
                                parseISO(ds.updated_at).getTime()
                            )
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <ConsolePage
            header={{
                title: INGESTION_NAV.title,
                icon: INGESTION_NAV.icon,
            }}
            subheader={
                <SearchBar
                    config={searchConfig}
                    onSearch={(text, filters, force) => {
                        filterDatasetIngestions(
                            filters,
                            setSearchParams,
                            force
                        );
                    }}
                    searchHide
                    defaultFilters={defaultFilters}
                />
            }
            content={
                <Table
                    className={styles.table}
                    data={allDatasetIngestions}
                    columns={columns}
                    rowKeyFunc={(ds) =>
                        ds.source_name +
                        "_" +
                        ds.dataset_name +
                        "_" +
                        ds.started_at
                    }
                    dataUnit="DatasetIngestionIngestion"
                    emptyText={
                        allDatasetIngestions.length > 0
                            ? "No ingestions found"
                            : "No ingestions created yet."
                    }
                />
            }
        />
    );
}

function filterDatasetIngestions(
    filters: Filter[],
    setSearchParams: (params: URLSearchParams) => void,
    force?: boolean
): DatasetIngestion["stats"] {
    const params = new URLSearchParams();
    if (filters.length) {
        filters.forEach((f) => {
            params.set(f.key.toString(), f.value.toString());
        });
        setSearchParams(params);
    } else if (force) {
        setSearchParams(params);
    }

    return [];
}

function buildSearchConfig(
    allDatasets: Dataset[],
    sourceInfo: SourceInfo,
    isDatasetPresent: boolean
): Config {
    const allSources = Object.keys(sourceInfo);
    const allSourcesSet = allSources.filter(
        (source) => sourceInfo[source].datasets.length > 0
    );
    const sources = allSourcesSet.map((source) => ({
        ...sourceInfo[source].metadata,
    }));
    const sourceConfig = {
        icon: SourceIcon,
        propertyName: "Sources",
        kind: {
            kind: "single-select" as const,
            options: sources.map((o) => ({
                key: o.name as string,
                name: o.name as string,
                value: o.name as string,
                metadata: { ...o },
                metadataSearch: true,
            })),
            valueCategory: "source",
            component: SourcesFilter,
            relationship: "has-one" as const,
        },
    };

    const statusConfig = {
        icon: CheckCircleIcon,
        propertyName: "Status",
        kind: {
            kind: "single-select" as const,
            options: Object.values(DatasetIngestionStatus).map((o) => ({
                key: o,
                name: o,
                value: o,
            })),
            valueCategory: "status",
            component: StatusFilter,
            relationship: "has-one" as const,
        },
    };

    const datasets = allDatasets
        .filter((d) => d.is_source_dataset)
        .map((d) => d.name)
        .sort();
    const datasetConfig = {
        icon: DatasetIcon,
        propertyName: "Dataset",
        kind: {
            kind: "single-select" as const,
            options: datasets.map((o) => ({ key: o, name: o, value: o })),
            valueCategory: "dataset",
            component: DatasetFilter,
            relationship: "has-one" as const,
        },
    };

    const filterOrder: string[] = ["status"];
    if (!isDatasetPresent) {
        filterOrder.push("dataset");
    }
    if (sources.length > 0 && isDatasetPresent) {
        filterOrder.push("source");
    }

    return {
        filterOrder,
        filterConfigs: {
            dataset: datasetConfig,
            source: sourceConfig,
            status: statusConfig,
        },
    };
}

const getIngestionText = (status: DatasetIngestionStatus, fraction: number) => {
    switch (status) {
        case DatasetIngestionStatus.COMPLETE:
            return "Finished";
        case DatasetIngestionStatus.INITIALIZING:
            return "Initializing...";
        case DatasetIngestionStatus.INGESTING:
            return `${(fraction * 100).toFixed(0)}% complete`;
        case DatasetIngestionStatus.FAILED:
            return "Failed";
        default:
            return "Ingesting...";
    }
};

const StatusFilter = ({ name }: { name: string }) => (
    <div className={styles.statusPill}>
        <IngestionIcon status={name as DatasetIngestionStatus} />
        {name}
    </div>
);

const StatusPill = ({
    status,
    fraction,
}: {
    status: DatasetIngestionStatus;
    fraction: number;
}) => {
    return (
        <div className={styles.statusPill}>
            <IngestionIcon status={status} />
            {getIngestionText(status, fraction)}
        </div>
    );
};

export default IngestionsPage;
