import classNames from "classnames";
import styles from "../styles/EntityPage.module.scss";

const Metadata = ({
    keyLabel,
    icon,
    keyValue,
    flexStart,
    dataTestLabel,
    dataTestValue,
}: {
    keyLabel: string;
    icon: JSX.Element;
    keyValue: string | JSX.Element;
    flexStart?: boolean;
    dataTestLabel?: string;
    dataTestValue?: string;
}) => {
    return (
        <div className={styles.metadataItem}>
            <div className={styles.metadataItemKey}>
                {icon}
                <span data-test={dataTestLabel}>{keyLabel}</span>
            </div>
            <div
                className={classNames(styles.metadataItemValue, {
                    [styles.flexEnd]: !flexStart,
                })}
            >
                <div
                    className={styles.metadataString}
                    data-test={dataTestValue}
                >
                    {keyValue}
                </div>
            </div>
        </div>
    );
};

export default Metadata;
