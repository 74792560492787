import FeaturesetIcon from "icons/featureset.svg";
import styles from "../styles/EntityPage.module.scss";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import { useUpdateEntityParams } from "../../../../context/CurrentView";
import Metadata from "./Metadata";

const Featureset = ({ name }: { name: string }) => {
    const { updateEntityParams: navigate } = useUpdateEntityParams();
    return (
        <Metadata
            icon={<FeaturesetIcon />}
            keyLabel="Featureset"
            keyValue={
                <div
                    onClick={() => navigate("featureset", name)}
                    className={styles.metadataLink}
                >
                    <div className={styles.metadataString}>{name}</div>
                    <ArrowNarrowUpRightIcon />
                </div>
            }
        />
    );
};

export default Featureset;
