import VersionIcon from "icons/stack.svg";
import Metadata from "./Metadata";

const Version = ({ version }: { version: string }) => {
    return (
        <Metadata
            icon={<VersionIcon />}
            keyLabel="Version"
            keyValue={version}
            dataTestLabel="metadata-version-test"
            dataTestValue="metadata-version-value-test"
        />
    );
};

export default Version;
