export interface PromResponse {
    data: PromResponseData;
}

export interface GeneralDataPoint<T> {
    x: Date;
    y: T;
}

export type DataPoint = GeneralDataPoint<number>;

interface PromResponseData {
    result: PromRangeVector; // only range vector matters for us right now
}

type PromRangeVector = {
    metric: { [key: string]: string };
    values: PromScalar[];
}[];

type PromScalar = [number, string];

export function parsePromResponse(
    resp: PromResponse,
    yMultiplier = 1
): DataPoint[] {
    if (resp.data.result.length == 1) {
        return resp.data.result[0].values
            .map((v) => {
                return {
                    x: new Date(v[0] * 1000),
                    y: parseFloat(v[1]) * yMultiplier,
                };
            })
            .filter((d) => !isNaN(d.y));
    }
    return [];
}

export function avgY(values: DataPoint[]): number {
    let s = 0;
    let cnt = 0;
    values.forEach((v) => {
        s += v.y;
        cnt += 1;
    });
    if (cnt > 0) {
        return s / cnt;
    }
    return 0;
}

export function getMinMax<T>(
    xys: GeneralDataPoint<T>[]
): { min: T; max: T } | undefined {
    if (xys.length == 0) {
        return undefined;
    }
    let min = xys[0].y;
    let max = xys[0].y;

    xys.forEach((xy) => {
        const { y } = xy;
        if (y < min) {
            min = y;
        }
        if (y > max) {
            max = y;
        }
    });

    return {
        min,
        max,
    };
}
